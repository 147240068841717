import styled from 'styled-components';

export const InputStyle = styled.input`
  background: #fff;
  border-radius: .8rem;
  padding: 8px;
`;

export const Title = styled.p`
    color: #fff;
    display: block;
`;
