import styled, { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
    p {
        margin: 0;
        padding: 0;
    }
    .bg {
        background: #fff;
    }

    .input_style {
        padding: 8px;
        border-radius: .8rem;
        background: #fff;
    }

    .title {
        color: #fff;
    }

    .flex-direction-column  {
        flex-direction: column;
    }

    button {
        background: #1AFA93;
        padding: 12px 20px;
        border-radius: .75rem;
        font-size: 1.4rem;
        color: #154166;
        border: none;
    }


    .bg-fixed {
        width: 100vw;
        height: 100vh;
        background: #fff; 
        position: fixed;
        z-index: -1;
          top: 0;
          left: 0;
        }

        .badge-danger {
            padding: .5rem;
            border-radius: 99px;
        }

        strong {
            font-size: 1.6rem;
            color: #154166;
        }



    .bg-dark-blue {
        background: #154166 !important;
    }
`;