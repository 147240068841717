import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Api from '../../../Api/Api';

function GraficoUsuarios() {
    const [graficouser, setGraficouser] = useState()

    useEffect(() => {
        Api.get("/Dashboard/Usuarios")
            .then(res => {
                setGraficouser(res.data)
            })
    }, [])

    return (
        <div className="card p-4 h-100" style={{ backgroundColor: "#45BFEE" }} >
            <h6 className='text-white'> Gráfico de Coletores </h6>
            <Doughnut
            options={{
                plugins: {
                    legend: {
                        labels: {
                            color: "#fff",
                        },
                    }
                }
            }}
                data={{
                    labels: graficouser?.map(e => e.regional),
                    datasets: [{
                        label: "",
                        data: graficouser?.map(e => e.total),
                        backgroundColor: [
                            "#154166",
                            "#72A5D0",
                            "#1AFA93",
                            "#427199",
                        ],
                        spacing: 0,
                        borderWidth: 0
                    }]
                }}
            />
        </div>
    );
}

export default GraficoUsuarios;