import React, { useEffect, useState } from 'react';
import AuthApi from '../../Api/AuthApi';
import { CSVLink } from 'react-csv'
import { useHistory } from 'react-router-dom'
import Api from '../../Api/Api';
import { Bar, PolarArea } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import personIcon from '../../assets/download/person-circle.svg'
import LeadsList from '../../assets/download/LeadsList.png'
import UsersList from '../../assets/download/UsersList.png'

import './Home.css'
import GraficoGerentes from '../../components/Input/Graficos/GraficoGerentes';
import GraficoUsuarios from '../../components/Input/Graficos/GraficoUsuarios';
import { BotaoDeslogar, BotaoExportar, CardContent, CardDark, CardGreen, CardGreenGridContainer, RankingContainer } from './Style';
import GraficoNucleos from '../../components/Input/Graficos/GraficoNucleos';

function Home() {
  const [list, setList] = useState([])
  const [listRank, setListRank] = useState([])
  const [graficoLeads, setGraficoLeads] = useState();
  const [totais, setTotais] = useState()
  const history = useHistory();
  const [loadCSV, setLoadCSV] = useState(false);
  const [regioes, setRegioes] = useState();
  // const [nucleos, setNucleos] = useState()
  const [estados, setEstados] = useState()

  useEffect(() => {
    AuthApi.listForm({
      result: (e) => {
        // console.log({ e })
        setList(e)
        setLoadCSV(true);
      },
      error: () => { }
    })
  }, [])

  useEffect(() => {
    AuthApi.listRanking({
      result: (e) => {
        setListRank(e)
      },
      error: () => { }
    })
  }, [])

  function formForm() {
    let newForm = [];
    list.forEach(o => {
      newForm.push({
        nome: o.nome,
        email: o.email,
        cpf: o.cpf,
        lat: o.lat,
        long: o.long,
        telefone: o.telefone,
        regional: o.regional,
        cpfUser: o.cpfUser,
        dataHora: o.dataHora,
        nomeUser: o.nomeUser,
        emailUser: o.emailUser,
        curso: o.curso,
        unidade: o.unidade,
        unidadeUser: o.unidadeUser,
        nucleo: o.nucleo,
        imagem1: o.imagens[0],
        imagem2: o.imagens[1],
        imagem3: o.imagens[2],
        imagem4: o.imagens[3],
        imagem5: o.imagens[4]
      })
    })
    return newForm
  }

  function totaisApi() {
    Api.get("/dashboard/Totais")
      .then(res => {
        setTotais(res.data)
      })
  }

  function graficoLeadApi() {
    Api.get("/Dashboard/Leads")
      .then(res => {
        setGraficoLeads(res.data)
      })
  }

  function graficoRegioesApi() {
    Api.get("/Dashboard/Regioes")
      .then(res => {
        setRegioes(res.data)
      })
  }

  // function graficoNucleosApi() {
  //   Api.get("/Dashboard/Nucleos")
  //     .then(res => {
  //       setNucleos(res.data)
  //     })
  // }

  function graficoEstadosApi() {
    Api.get("/Dashboard/Estados")
      .then(res => {
        setEstados(res.data)
      })
  }

  useEffect(() => {
    totaisApi();
    graficoLeadApi()
    graficoRegioesApi()
    graficoEstadosApi()
  }, [])

  const csExport = {
    data: formForm(),
    headers: [
      { label: "Nome", key: "nome" },
      { label: "Email", key: "email" },
      { label: "CPF", key: "cpf" },
      { label: "Telefone", key: "telefone" },
      { label: "Nome Colaborador", key: "nomeUser" },
      { label: "CPF Colaborador", key: "cpfUser" },
      { label: "Unidade Colaborador", key: "unidadeUser" },
      { label: "Regional", key: "regional" },
      { label: "Data e Hora", key: "dataHora" },
      { label: "Email Colaborador", key: "emailUser" },
      { label: "Latitude", key: "lat" },
      { label: "Longitude", key: "long" },
      { label: "Unidade", key: "unidade" },
      { label: "Curso", key: "curso" },
      { label: "Núcleo", key: 'nucleo' },
      { label: "Imagem 1", key: "imagem1" },
      { label: "Imagem 2", key: "imagem2" },
      { label: "Imagem 3", key: "imagem3" },
      { label: "Imagem 4", key: "imagem4" },
      { label: "Imagem 5", key: "imagem5" },
    ],
    filename: "Lista Formulário.csv"
  }

  return (
    <>
      <div className="bg-fixed" />

      <div className="container">
        {/* Menu */}
        <div className="my-4 d-flex flex-dir-sm-column justify-content-center justify-content-md-end">
          <BotaoExportar onClick={() => history.push("/perfil")}>
            <img src={personIcon} />
          </BotaoExportar>
          {
            loadCSV && (
              <BotaoExportar className='mx-0 mx-md-4'>
                <CSVLink {...csExport} className="text-white">
                  Exportar usuários para CSV
                </CSVLink>
              </BotaoExportar>
            )
          }
          {
            !loadCSV && (
              <button disabled={true}>l
                Carregando CSV...
              </button>
            )
          }
          <BotaoDeslogar onClick={() => {
            history.replace("Login")
            localStorage.clear();
          }}>
            Deslogar
          </BotaoDeslogar>
        </div>

        <div className='grid-style'>
          <div>
            <GraficoGerentes />
          </div>

          <div>
            <GraficoUsuarios />
          </div>
          <div>

            <div className='grid-2'>
              <GraficoNucleos />

              <CardGreenGridContainer>
                <TotalUsuarios totais={totais} UsersList={UsersList} />
                <TotalLeads totais={totais} LeadsList={LeadsList} UsersList={UsersList} />
              </CardGreenGridContainer>

              <GraficoEstados estados={estados} />
              <GraficoRegionais regioes={regioes} />
            </div>

          </div>

          <div>
            <RankingList listRank={listRank} />
          </div>

        </div>

        {/* <Scatter 
        data={{
          datasets: !gerentes ? [] : gerentes?.map(g => {
          return {
            label: g.gerente,
            data: {
              x: g.quantidadeUsuarios,
              y: 5,
              // x: 1,
              // y: Math.random() * 4
            },
            backgroundColor: "#154166"
          }
        })}}
      />     */}


      </div>
    </>
  );
}

export default Home;

const GraficoEstados = ({ estados }) => (
  <div className="card p-4" style={{
    backgroundColor: "#45BFEE",
  }} >
    <strong style={{
      color: "#fff"
    }}>
      Gráfico de Estados
    </strong>
    <Bar
      plugins={{
        datalabels: {
          display: true,
          color: "#000"
        }
      }}
      data={{
        labels: estados?.map(e => e.estado ? e.estado + ' (' + e.quantidade + ')' : ""),
        datasets: [{

          label: '',
          data: estados?.map(e => e.quantidade),
          backgroundColor: [
            "#154166"
          ]
        }]
      }}
    />
  </div>
)


// const GraficoNucleos = ({ nucleos }) => (
//   <div className="card p-4" style={{
//     backgroundColor: "#45BFEE",
//   }} >
//     <strong style={{
//       color: "#fff"
//     }}>
//       Gráfico de Núcleos
//     </strong>
//     <Bar
//       data={{
//         labels: nucleos?.map(e => e.nucleo + ' (' + e.quantidade + ')'),
//         datasets: [{

//           label: '',
//           data: nucleos?.map(e => e.quantidade),
//           backgroundColor: [
//             "#154166"
//           ]
//         }]
//       }}
//     />
//   </div>
// )

const TotalLeads = ({ LeadsList, totais }) => (
  <CardGreen>
    <CardContent>
      <h6>Total de Leads</h6>
      <p>{totais?.leads}</p>
    </CardContent>
    <img src={LeadsList} />
  </CardGreen>
)

const TotalUsuarios = ({ UsersList, totais }) => (
  <CardGreen>
    <CardContent>
      <h6>Total de Usuários</h6>
      <p>{totais?.usuarios}</p>
    </CardContent>
    <img src={UsersList} className="" />
  </CardGreen>
)

const GraficoRegionais = ({ regioes }) => (
  <div className="card p-2" style={{
    backgroundColor: "#45BFEE",
  }} >
    <h6 style={{
      color: "#fff"
    }}>
      Gráfico de Regionais
    </h6>
    <PolarArea

      data={{
        labels: regioes?.map(e => e.regiao),
        datasets: [{
          data: regioes?.map(e => e.quantidade),
          backgroundColor: [
            "#154166",
            "#215B8C",
            "#427199",
            "#73A5D0",
          ],
          borderWidth: 0,
          datalabels: {
            opacity: 1
          }
        }]
      }}
    />
  </div>
)


const RankingList = ({ listRank }) => (
  <RankingContainer className="card p-3">
    <h6>Ranking</h6>

    {
      listRank.map(e => (
        <CardDark key={e.nome} className="card m-2 p-3">
          <div className="d-flex justify-content-between mb-2">
            <p>Nome:</p>
            <p className="font-weight-bold text-right">{e.nome}</p>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <p>Formulários cadastrados:</p>
            <p className="font-weight-bold">{e.totalFormularios}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Regional:</p>
            <p className="font-weight-bold">{e.regional}</p>
          </div>
        </CardDark>
      ))
    }
  </RankingContainer>
)