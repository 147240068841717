import React from 'react';
import { InputStyle, Title } from './Style';

function Input({
    value,
    title,
    type,
    onChange,
    inputStyle = {},
    labelStyle = {},
    readOnly = false
}) {
    return (
        <div className="d-flex flex-direction-column">
            <Title style={labelStyle}>{title}</Title>
           
           <InputStyle
                value={value}
                onChange={onChange}
                type={type}
                style={inputStyle}
                readOnly={readOnly}
            />
        </div>
    );
}

export default Input;