import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AuthApi from '../Api/AuthApi';
import Input from '../components/Input/Index'
import logo from '../assets/logo.png'
import styled from 'styled-components';

function Login() {
    const [email, setEmail] = useState("")
    const [senha, setSenha] = useState("")
    const history = useHistory()


    function logar() {
        const data = {
            email,
            password: senha
        }
        if (email !== "" || senha !== "") {
            AuthApi.SignIn({
                email,
                password: senha,
                result: () => {
                    history.push("Home")
                },
                error: () => { }
            },

            )
        }
    }


    return (
        <div className="vh-100 justify-content-center align-items-center bg">
            <Container className='d-flex justify-content-center align-items-center'>
                <div className="col-6">
                    <div className="text-center">
                        <img src={logo} className="w-50" />
                        <div>
                            <p>2022</p>
                        </div>
                    </div>
                <Input 
                    title="Email"
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input 
                    title="Senha"
                        value={senha}
                        type="password"
                        onChange={(e) => setSenha(e.target.value)}
                    />
                    <div className="d-flex pt-5 justify-content-center">
                    <Button 
                        onClick={() => logar()}>
                        Entrar
                    </Button>
                    </div>
                </div>
           </Container>
        </div>
    );
}

export default Login;

const Container = styled.div`
    height: 90%;
    width: 95%;
    background-color: #45BFEE;
    border-bottom-right-radius: 100px;
`

const Button = styled.button`
    background: #154166;
    color: #fff;
`