import React, { useEffect, useState } from 'react'
import Input from '../components/Input/Index'
import styled from 'styled-components'
import Pefil from '../assets/download/perfil.png'
import ArrowLeft from '../assets/download/ArrowLeft.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Api from '../Api/Api'

export const Perfil = () => {
  const [dados, setDados] = useState(dadosInitialValue)

  const history = useHistory();

  useEffect(() => {

    Api.get('/perfil')
      .then(e => setDados(e.data))
  }, [])

  return (
    <Container>
      
        <VoltarButton
          onClick={() => history.push('Home')}
          >
          <span className='mr-1 my-4'>
            <img 
              src={ArrowLeft}
              />
          </span>
          Voltar
        </VoltarButton>

        <PerfilContainer>
          <h5 style={{ color: '#154166' }} >Seu Perfil</h5>

          <CamposContainer>

              <Imagem className='d-flex'>
                <img
                  src={Pefil}
                  />
              </Imagem>


              <Input 
                title="Nome"
                labelStyle={{ color: '#154166', fontWeight: 'bold' }}
                inputStyle={{ marginBottom: 15, borderColor: '#154166' }}
                value={dados.nome}
                readOnly={true}
                />

              <Input 
                title="Email"
                labelStyle={{ color: '#154166', fontWeight: 'bold' }}    
                inputStyle={{ marginBottom: 15, borderColor: '#154166' }}
                value={dados.email}
                readOnly
                />

              <Input 
                title="CPF"
                labelStyle={{ color: '#154166', fontWeight: 'bold' }}inputStyle={{ marginBottom: 15, borderColor: '#154166' }}
                value={dados.cpf}
                readOnly={true}
                
                />

              <Input 
                title="Telefone"
                labelStyle={{ color: '#154166', fontWeight: 'bold' }}
                inputStyle={{ borderColor: '#154166' }} 
                value={dados.telefone}
                readOnly
                /> 

              <p
                style={{ color: '#999', marginTop: 20 }}
                >
                * Informações Somente para Consulta
              </p>

          </CamposContainer>


        </PerfilContainer>
    </Container>
  )
}



const dadosInitialValue = {
  nome: "",
  telefone: "",
  cpf: "",
  email: ""
}


const VoltarButton = styled.button`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  background: #154166;
  padding: 8px 16px;
  font-size: 1.4rem;
  border-radius: .25rem;
  border: 1px solid #154166;
  color: #fff;
  margin: 0;
  border-radius: .25rem;
`


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

const PerfilContainer = styled.div`
  border: 1px solid #1198D7;
  border-radius: .75rem;
  padding: 20px;
  width: 50vw;
`

const Imagem = styled.div`
  display: flex;
  align-self: center;
  justify-content: center
`

const CamposContainer = styled.div`
  border: 1px dashed #000;
  padding: 20px 50px 50px 50px;
`