import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Api from '../../../Api/Api';

function GraficoGerentes() {
    const [gerentes, setGerentes] = useState([]);

    // function tooltipFormat(toolData) {
    //     const template = [
    //         `Nome do(a) Gerente: ${gerentes[toolData.dataIndex].gerente}`,
    //         `Usuários: ${toolData.parsed.x}`,
    //         `Leads capturados no total: ${toolData.parsed.y}`];
    //     return template
    // }

    useEffect(() => {
        Api.get("/Dashboard/Gerentes")
            .then(res => {
                console.log({ "/Dashboard/Gerentes(response)": res.data })
                const newFormList = res.data?.map(e => ({
                    gerente: e.gerente,
                    quantidadeLeads: e.quantidadeLeads.reduce(function (somar, i) {
                        return somar + i;
                    }),
                    quantidadeUsuarios: e.quantidadeUsuarios
                }))
                setGerentes(newFormList);
            })
    }, [])

    return (
        <div className="card bg-dark-blue p-4">
            <h6 className='text-white'>Gráfico de Gerentes</h6>
            <Bar
                options={{
                    scales: {
                        yAxes: {
                            grid: {
                                color: "#fff2",
                            },
                            ticks: {
                                color: "#ffffff",
                                precision: 0,
                            },
                            // suggestedMax: 5
                        },
                        xAxes: {
                            grid: {
                                color: "#fff2",
                            },
                            ticks: {
                                color: "#ffffff",
                                precision: 0
                            },
                            // suggestedMin: 3
                        },

                    },
                    plugins: {
                        // tooltip: {
                        //     callbacks: {
                        //         label: tooltipFormat,
                        //     }
                        // },
                        legend: {
                            labels: {
                                color: "#fff",
                            },
                        }
                    },
                   
                }}
                plugins={{
                    datalabels: {
                        display: true,
                        color: "#fff"
                    }
                }}
                data={{
                    labels: gerentes.map(e => e.gerente),
                    datasets: [{
                        label: "Leads capturados",
                        fill: false,
                        data: gerentes.map(e => e.quantidadeLeads),
                        backgroundColor: 'rgb(69, 191, 238)',
                    }]
                }}
            />
        </div>
    );
}

export default GraficoGerentes;