import styled from "styled-components";

export const BotaoDeslogar = styled.button`
background: #fff;
color: #154166;
border: 1px solid #154166;
border-radius: .75rem;
font-size: 1.2rem;
margin-bottom: 8px;
`

export const BotaoExportar = styled.label`
background: #154166;
padding: 12px 20px;
cursor: pointer;
font-size: 1.2rem;
border-radius: .25rem;
border: none !important;
margin: 0;
display: flex ;
justify-content: center;
align-items:center;
margin-bottom: 8px;
border-radius: .75rem;
`

export const RankingContainer = styled.div`
background: #154166;
color: #fff;
border-radius: .8rem;
`

export const CardDark = styled.div`
color: #000;
border-radius: 4px;
`

export const CardGreenGridContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
`;

export const CardGreen = styled.div`
background: #1AFA93;
width: 100%;
height: 100% ;
position: relative;
border-radius: 4px;
overflow: hidden;

h6 {
  font-size: 1.2rem;
  font-weight: bold;
}

p {
  font-size: 1.4rem;
  font-weight:
}

img {
  max-width: 240px;
  width: 100%;
  position: absolute;
  bottom: -25%;
  right: -25%;
}
`;

export const CardContent = styled.div`
position: absolute;
z-index: 1;
padding: 1.5em;
`