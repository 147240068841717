import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Api from '../../../Api/Api';

function GraficoNucleos() {
    const [nucleos, setNucleos] = useState()

    useEffect(() => {
        Api.get("/Dashboard/Nucleos")
            .then(res => {
                setNucleos(res.data)
            })
    }, [])

    return (
        <div className="card p-4" style={{
            backgroundColor: "#45BFEE",
        }} >
            <h6 className='text-white'> Gráfico de Núcleos</h6>
            <Bar
                data={{
                    labels: nucleos?.map(e => e.nucleo + ' (' + e.quantidade + ')'),
                    datasets: [{
                        label: '',
                        data: nucleos?.map(e => e.quantidade),
                        backgroundColor: [
                            "#154166"
                        ]
                    }]
                }}
            />
        </div>
    );
}

export default GraficoNucleos;