import React from 'react';
import AuthApi from '../Api/AuthApi';
import logo from '../assets/logo_white.png'

import image1 from '../assets/download/1.png'
import image2 from '../assets/download/2.png'
import image3 from '../assets/download/3.png'
import image4 from '../assets/download/4.png'
import image5 from '../assets/download/5.png'
import image6 from '../assets/download/6.png'
import image7 from '../assets/download/7.png'

function Download() {

    function downloadApk() {
        window.open("https://henryleads.henrystore.com.br/api/download", "_blank")
    }

    return (
        <div className="vh-100 d-flex justify-content-center">
            <div className="bg-fixed" />
            <div className="col-12 col-md-6">
                <div className="text-center pt-5">
                    <img src={logo} className="w-100 pb-4" />
                </div>

                <div className="text-center">
                    <button onClick={() => downloadApk()}>
                        Download(.apk)
                    </button>
                </div>
                <hr className="text-white bg-white mb-5" />

                <div className="row">
                    <div className="col-6">
                        <span className="badge-danger">Etapa 1</span>
                        <img src={image1} alt="img1" className="w-100" />
                    </div>

                    <div className="col-6">
                        <span className="badge-danger">Etapa 2</span>
                        <img src={image2} alt="img2" className="w-100" />
                    </div>

                    <div className="col-6">
                        <span className="badge-danger">Etapa 3</span>
                        <img src={image3} alt="img3" className="w-100" />
                    </div>
                    <div className="col-6">
                        <span className="badge-danger">Etapa 4</span>
                        <img src={image4} alt="img4" className="w-100" />
                    </div>
                    <div className="col-6">
                        <span className="badge-danger">Etapa 5</span>
                        <img src={image5} alt="img5" className="w-100" />
                    </div>
                    <div className="col-6">
                        <span className="badge-danger">Etapa 6</span>
                        <img src={image6} alt="img6" className="w-100" />
                    </div>
                    <div className="col-6">
                        <span className="badge-danger">Etapa 7</span>
                        <img src={image7} alt="img7" className="w-100" />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Download;