import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import AtualizarUsuario from './pages/AtualizarUsuario';
import Carregando from './pages/Carregando';
import Download from './pages/Download';
import Home from './pages/Home/Home';
import Login from './pages/login';
import { Perfil } from './pages/Perfil';
import Privacidade from './pages/Privacidade';
import { Global } from './style/Global'

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
        <Global />
        <Switch>
          <Route path="/" component={Carregando} exact />
          <Route path="/Login" component={Login} exact />
          <Route path="/Home" component={Home} exact />
          <Route path="/Download" component={Download} exact />
          <Route path="/AtualizarUsuario" component={AtualizarUsuario} exact />
          <Route path="/Privacidade" component={Privacidade} exact />
          <Route path="/Perfil" component={Perfil} exact />
        </Switch>
      </>
    );
  }
}