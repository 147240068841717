import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

function Carregando() {
    const history = useHistory();
    useEffect(() => {
        const token = localStorage.getItem("@HLEADS_TOKEN")
        if (token) {
            history.replace("Home")
        } else {
            history.replace("Login")
        }
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            Carregando...
        </div>
    );
}

export default Carregando;